/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';

import {
  GatsbyImage, getImage, ImageDataLike, IGatsbyImageData,
} from 'gatsby-plugin-image';
import { graphql, PageProps } from 'gatsby';
import DetailPageContainer from '../components/DetailPageContainer';
import Text from '../components/Text';

const rootStyle = css`
  padding: 0 24px;
`;

const imgContainerStyle = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px 0;
  margin-right: -20px;
`;

const imgStyle = css`
  border-radius: 50%;
  margin: 20px 20px 0px 0px;
  flex-basis: 150px;
  max-width: 300px;
  flex-shrink: 0;
  flex-grow: 1;
`;

type Props = {
  title: string
  imgAndrea: ImageDataLike
  imgTimon: ImageDataLike
  description: string
};

export function AboutUsPageTemplate(props: Props): React.ReactElement {
  const {
    title, imgAndrea, imgTimon, description,
  } = props;
  return (
    <DetailPageContainer title={title} css={rootStyle}>
      <div css={imgContainerStyle}>
        <GatsbyImage alt="portrait_andrea" css={imgStyle} image={getImage(imgAndrea) as IGatsbyImageData} />
        <GatsbyImage alt="portrait_timon" css={imgStyle} image={getImage(imgTimon) as IGatsbyImageData} />
      </div>
      <Text>
        {description}
      </Text>
    </DetailPageContainer>
  );
}

type AboutUsQueryData = {
  prismicAboutUs: {
    data: {
      title: string
      image_andrea: {
        localFile: ImageDataLike
      }
      image_timon: {
        localFile: ImageDataLike
      }
      description: {
        text: string
      }
    }
  }
};

function AboutUsPage(props: PageProps<AboutUsQueryData>): React.ReactElement {
  const { data } = props;
  const aboutUsData = data.prismicAboutUs.data;
  return (
    <AboutUsPageTemplate
      title={aboutUsData.title}
      imgAndrea={aboutUsData.image_andrea.localFile}
      imgTimon={aboutUsData.image_timon.localFile}
      description={aboutUsData.description.text}
    />
  );
}

export const pageQuery = graphql`
  query AboutUsQuery {
    prismicAboutUs {
      data {
        title
        image_andrea {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 250
                height: 250
                placeholder: BLURRED
              )
            }
          }
        }
        image_timon {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 250
                height: 250
                placeholder: BLURRED
              )
            }
          }
        }
        description {
          text
        }
      }
    }
  }
`;

export default AboutUsPage;
